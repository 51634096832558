var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"prensa",attrs:{"element-loading-text":"Cargando Noticias..","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('Breadcrumb'),_c('BannerMicro',{attrs:{"texto":_vm.texto,"imagen":_vm.imagenBanner}}),_c('center',[_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"Buscar","prefix-icon":"el-icon-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"card"},_vm._l((_vm.filterData),function(noticia,index){return _c('el-col',{key:index,staticClass:"card-col",attrs:{"span":8}},[_c('router-link',{attrs:{"to":{ name: 'noticia',
                           params: {
                             id: noticia.idTimeline,
                             contenido: noticia.contenido,
                             titulo: noticia.titulo,
                             imagen: noticia.imagenes
                           }
                         }}},[_c('el-card',{attrs:{"body-style":{ padding: '0px' }}},[_c('div',{staticClass:"card-contenedor-imagen"},[(noticia.imagenes[0])?_c('img',{staticClass:"card-image",attrs:{"src":`https://equitadm.tekfoster.com/imagen_timeline/${noticia.imagenes[0].nombre_imagen}`}}):_c('img',{staticClass:"card-image",attrs:{"src":require("../assets/sinimagen.jpg")}})]),_c('div',{staticStyle:{"padding":"14px"}},[_c('span',{staticClass:"card-titulo"},[_vm._v(_vm._s(noticia.titulo))]),_c('div',{staticClass:"card-bottom card-clearfix"},[_c('time',{staticClass:"card-fecha"},[_vm._v(_vm._s(noticia.fecha))]),_c('el-button',{staticClass:"card-button",attrs:{"type":"text"}},[_vm._v("Ver más")])],1)])])],1)],1)}),1),_c('center',[_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"Buscar","prefix-icon":"el-icon-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }