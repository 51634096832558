import { render, staticRenderFns } from "./_micrositio.vue?vue&type=template&id=91985654&scoped=true&"
import script from "./_micrositio.vue?vue&type=script&lang=js&"
export * from "./_micrositio.vue?vue&type=script&lang=js&"
import style0 from "./_micrositio.vue?vue&type=style&index=0&id=91985654&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91985654",
  null
  
)

export default component.exports