import { render, staticRenderFns } from "./_privacidad.vue?vue&type=template&id=092445a1&scoped=true&"
var script = {}
import style0 from "./_privacidad.vue?vue&type=style&index=0&id=092445a1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092445a1",
  null
  
)

export default component.exports