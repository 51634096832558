import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/es";
import VueAnalytics from "vue-analytics";
import VueFacebookPage from 'vue-facebook-page'

Vue.use(ElementUI, { locale });
Vue.use(VueAnalytics, { id: "UA-98864338-1", router });
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
