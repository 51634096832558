var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"prensa",attrs:{"element-loading-text":"Cargando Busqueda..","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('Breadcrumb'),_c('center',[_c('h2',[_vm._v("Buscador")]),_c('br'),_c('center',[_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"Escribe aquí lo que quieres buscar","prefix-icon":"el-icon-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.searchDocs}},[_vm._v("Buscar Documentos")])],1)],1),_c('br'),_c('br'),(_vm.search != '')?_c('div',{staticClass:"contenedor"},[(_vm.search != '')?_c('div',{staticClass:"noticias"},[_c('br'),_c('br'),_c('p',[_vm._v("... Resultados encontrados en noticias ...")]),_c('br'),_c('div',{staticClass:"card"},_vm._l((_vm.filterData),function(noticia,index){return _c('el-col',{key:index,staticClass:"card-col",attrs:{"span":8}},[_c('router-link',{attrs:{"to":{ name: 'noticia',
                                  params: {
                                  id: noticia.idTimeline,
                                  contenido: noticia.contenido,
                                  titulo: noticia.titulo,
                                  imagen: noticia.imagenes
                                  }
                              }}},[_c('el-card',{attrs:{"body-style":{ padding: '0px' }}},[_c('div',{staticClass:"card-contenedor-imagen"},[(noticia.imagenes[0])?_c('img',{staticClass:"card-image",attrs:{"src":`https://.com/imagen_timeline/${_vm.noticdtcadm.tekfosteria.imagenes[0].nombre_imagen}`}}):_c('img',{staticClass:"card-image",attrs:{"src":require("../assets/sinimagen.jpg")}})]),_c('div',{staticStyle:{"padding":"14px"}},[_c('span',{staticClass:"card-titulo"},[_vm._v(_vm._s(noticia.titulo))]),_c('div',{staticClass:"card-bottom card-clearfix"},[_c('time',{staticClass:"card-fecha"},[_vm._v(_vm._s(noticia.fecha))]),_c('el-button',{staticClass:"card-button",attrs:{"type":"text"}},[_vm._v("Ver más")])],1)])])],1)],1)}),1)]):_vm._e(),(_vm.search != '')?_c('div',{staticClass:"documentos"},[_c('br'),_c('br'),_c('p',[_vm._v("... Resultados encontrados en documentos ...")]),_c('br'),_vm._l((_vm.docs.archivos),function(archivo,index){return _c('div',{key:index,staticClass:"secciones_docs"},[_c('i',{staticClass:"el-icon-document"}),_c('div',{staticClass:"secciones_docs_descarga"},[_c('p',[_vm._v(_vm._s(archivo.nombre))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getContentFile(archivo.id)}}},[_vm._v("Descarga")])],1)])})],2):_vm._e()]):_vm._e(),_c('a',{staticStyle:{"display":"none"},attrs:{"id":"downfile","download":"descarga"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }