import { render, staticRenderFns } from "./_noticiaDetalle.vue?vue&type=template&id=f2862de6&scoped=true&lang=html&"
import script from "./_noticiaDetalle.vue?vue&type=script&lang=js&"
export * from "./_noticiaDetalle.vue?vue&type=script&lang=js&"
import style0 from "./_noticiaDetalle.vue?vue&type=style&index=0&id=f2862de6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2862de6",
  null
  
)

export default component.exports