<template>
  <div class="principal">

      <Breadcrumb></Breadcrumb>
      

    <div class="contenido">
      <div class="texto">
        <p>
        Los medios disponibles de atención y participación que tienen los ciudadanos para presentar Peticiones, Quejas , Reclamos, Sugerencias y Denuncias de Actos de Corrupción en VillavoEmprende, son:<br><br>

        <b>HORARIO DE ATENCIÓN AL PÚBLICO:</b> DE lunes a viernes en días hábiles laborales de 7:00 am a 5:00 pm.
        <br><br>

        <b>LINEA DE ATENCIÓN:</b> Cel: (+57) 310 205 0788
        <br><br>

        <b>DIRECCIÓN:</b>Calle 41 # 31 – 02 Antiguo Hotel Savoy
               <br><br>Villavicencio, Colombia.
        <br><br>

        <b>PORTAL WEB INSTITUCIONAL:</b> www.equitgrow.com
        </p>
      </div>

      <div class="google_maps">
        <iframe width="100%" height="100%" frameborder="0" style="border:0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.3375587149626!2d-73.6404637857275!3d4.153867196979888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3e2deefe749fc9%3A0xd6cf935211c9106d!2sCl.%2041%20%2331-2%2C%20Villavicencio%2C%20Meta!5e0!3m2!1ses!2sco!4v1640961917758!5m2!1ses!2sco" allowfullscreen></iframe>
      </div>
    </div>

  </div>

</template>

<script>
import BannerMicro from './bannerMicro.vue'
import Breadcrumb from './breadcrumb.vue'

export default {
  components: {BannerMicro, Breadcrumb},
  name: 'app',
  data(){
    return{
      texto:'Medios de Contacto',
      imagenBanner:'https://www.tekfoster.com/web/sites/default/files/adjuntos/ocad/Banner_Planeacion.jpg'
    }
  }
}
</script>

<style scoped>
h2{
  font-size: 30px;
  font-weight: 600;
  color: #37474F;
}
p{
  font-size: 10pt;
  font-weight: 400;
  color: #4a4a4a;
  letter-spacing: 0.2px;
}
h3{
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  letter-spacing: 0.2px;
}
.principal{
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
  padding: 12px
}

.contenido{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.texto{
  display: flex;
  flex: 1;
  min-width: 400px;
  box-shadow: 0 0 10px 0 rgba(94, 92, 92, 0.22);
  padding: 28px;
  margin-right: 20px
}
.google_maps{
  display: flex;
  min-width: 400px;
}

@media  screen and (max-width: 900px){

.texto{
  margin-right: 0px;
  margin-bottom: 15px;
}
.google_maps{
  flex: 1;
  height: 400px;
}
.banner{
  height: 280px;
}

}
@media  screen and (max-width: 490px){
  .banner_text{
    padding: 10px;
    height: 40px;
    width: 100%;
    background:rgba(255,255,255,0.7)
  }
  .contenido{
    flex-direction: column;
  }
  .texto{
    width: 100%;
    min-width: 100px;
    box-sizing: border-box;
  }
  .google_maps{
    min-width: 100px;
    width: 100%
  }
  h2{
    font-size: 18px;
    font-weight: 500;
    color: #37474F;
  }


}

</style>
