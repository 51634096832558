<template lang="html">
  <h1>gestion de calidad</h1>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
