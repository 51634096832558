import Vue from "vue";
import Router from "vue-router";
import Inicio from "@/components/_inicio.vue";
import Meta from "@/components/_meta.vue";
import Galeria from "@/components/_galeria.vue";
import Entidad from "@/components/_entidad.vue";
import Atencion from "@/components/_atencion.vue";
import Prensa from "@/components/_prensa.vue";
import Transparencia from "@/components/_transparencia.vue";
import Soporte from "@/components/_soporte.vue";
import Ayuda from "@/components/_ayuda.vue";
import Sitemap from "@/components/_sitemap.vue";
import Privacidad from "@/components/_privacidad.vue";
import Condiciones from "@/components/_condiciones.vue";
import Contacto from "@/components/_contacto.vue";
import Micrositio from "@/components/_micrositio.vue";
import Gobernadora from "@/components/_gobernadora.vue";
import Documentacion from "@/components/_centroDocumentacion.vue";
import Calidad from "@/components/_gestionCalidad.vue";
import NoticiaDetalle from "@/components/_noticiaDetalle.vue";
import Convocatorias from "@/components/_convocatorias.vue";
import CategoriasConvocatorias from "@/components/_categoriasConvocatorias.vue";
import ListadoConvocatorias from "@/components/_listadoConvocatorias.vue";
import DetallesConvocatorias from "@/components/_detallesConvocatorias.vue";
import Buscador from "@/components/_buscador.vue";
Vue.use(Router);

export default new Router({
  mode: "hash",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "inicio",
      component: Inicio
    },
    {
      path: "/transparencia",
      name: "transparencia",
      component: Transparencia
    },
    {
      path: "/meta",
      name: "meta",
      component: Meta
    },

    {
      path: "/galeria",
      name: "galeria",
      component: Galeria
    },
    {
      path: "/prensa",
      name: "prensa",
      component: Prensa
    },
    {
      path: "/buscador",
      name: "buscador",
      component: Buscador
    },
    {
      path: "/noticia/:id",
      name: "noticia",
      component: NoticiaDetalle
    },
    {
      path: "/privacidad",
      name: "privacidad",
      component: Privacidad
    },
    {
      path: "/condiciones",
      name: "condiciones",
      component: Condiciones
    },
    {
      path: "/contacto",
      name: "contacto",
      component: Contacto
    },
    {
      path: "/ayuda",
      name: "ayuda",
      component: Ayuda
    },
    {
      path: "/soporte",
      name: "soporte",
      component: Soporte
    },
    {
      path: "/sitemap",
      name: "sitemap",
      component: Sitemap
    },
    {
      path: "/micrositio/:id",
      name: "micrositio",
      component: Micrositio
    },
    {
      path: "/entidad/gobernadora",
      name: "Entidad / Gobernadora",
      component: Gobernadora
    },
    {
      path: "/documentacion/documentos",
      name: "documentación / Centro de documentación",
      component: Documentacion
    },
    {
      path: "/documentacion/convocatorias",
      component: Convocatorias,
      children: [
        {
          path: "/",
          component: CategoriasConvocatorias,
          name: CategoriasConvocatorias
        },
        {
          path: "listado/:id",
          component: ListadoConvocatorias
        },
        {
          path: "detalles/:id",
          component: DetallesConvocatorias
        }
      ]
    },
    // {
    //   path: '/documentacion/convocatorias/listado',
    //   name: 'listadoConvocatorias',
    //   component: ListadoConvocatorias
    // },
    {
      path: "/documentacion/calidad",
      name: "calidad",
      component: Calidad
    },
    {
      path: "/redirect",
      name: "redirect",
      beforeEnter(to) {
        if (to.query.newtab == "true") {
          window.open(to.query.link, "_blank");
        } else {
          window.location = to.query.link;
        }
      }
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});
